import React from "react";
import CampaignLogoTitle from "../../CampaignLogoTitle";
import CampaignSection from "../../components/CampaignSection";
import CoffinIcon from "./coffin.svg";
import MicroscopeIcon from "./microscope.svg";
import SunIcon from "./sun.svg";
import "./CampaignStatistic.scss";

const CARDS_CONTENT = [
  {
    icon: {
      src: CoffinIcon,
      alt: "trumna",
    },
    value: "20%",
    description:
      "o tyle wyższa jest umieralność na czerniaka w Polsce niż w innych krajach UE.",
  },
  {
    icon: {
      src: SunIcon,
      alt: "słońce",
    },
    value: "5 min.",
    description:
      "Już nawet po 5 minutach ekspozycji na słońce zwiększa się ryzyko zachorowalności u niektórych osób.",
  },
  {
    icon: {
      src: MicroscopeIcon,
      alt: "mikroskop",
    },
    value: "300%",
    description:
      "o tyle wzrosła zachorowalność na czerniaka w ciągu ostatnich 30 lat.",
  },
];

const CampaignStatistic = () => {
  return (
    <CampaignSection
      id="campaign-statistic"
      className="campaign-statistic"
      subtitle="Statystyki"
      title={
        <>
          Dlaczego mówimy: nie przekonuj się <CampaignLogoTitle />?
        </>
      }
      paragraph="Szacuje się, że w ciągu swojego życia 1 na 27 mężczyzn i 1 na 40
      kobiet może zachorować na czerniaka. Nie graj w rosyjską ruletkę ze
      swoim zdrowiem."
    >
      {CARDS_CONTENT.map(({ icon: { src, alt }, value, description }, i) => {
        return (
          <div key={i} className="campaign-statistic__card">
            <img
              src={src}
              className="campaign-statistic__card-icon"
              alt={alt}
            />
            <h3 className="campaign-statistic__card-value">{value}</h3>
            <p className="campaign-statistic__card-description">
              {description}
            </p>
          </div>
        );
      })}
    </CampaignSection>
  );
};

export default CampaignStatistic;
