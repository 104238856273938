import React, { useEffect, useState } from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import "./CampaignCarousel.scss";
import { useScrollCarousel } from "../../../../hooks/useScrollCarousel";

const CampaignCarousel = ({
  className,
  children,
  theme = "light",
}: {
  className?: string;
  children: React.ReactElement[];
  theme?: "light" | "dark";
}) => {
  const {
    wrapperProps,
    scrollBack,
    scrollNext,
    isScroll,
    isLeftBtnDisabled,
    isRightBtnDisabled,
  } = useScrollCarousel(children.length, false);

  return (
    <div className="carousel-padding-wrapper">
      <div
        className={`${
          className ? `${className}__carousel` : ""
        }campaign-carousel`}
      >
        {isScroll && (
          <>
            <button
              className={`campaign-carousel__btn campaign-carousel__btn--${theme} campaign-carousel__left-btn`}
              onClick={scrollBack}
              disabled={isLeftBtnDisabled}
            >
              <LeftArrow />
            </button>
            <button
              className={`campaign-carousel__btn campaign-carousel__btn--${theme} campaign-carousel__right-btn`}
              onClick={scrollNext}
              disabled={isRightBtnDisabled}
            >
              <RightArrow />
            </button>
          </>
        )}
        <div className={"campaign-carousel__content"} {...wrapperProps}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CampaignCarousel;
