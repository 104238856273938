import React from "react";

import CampaignHero from "./CampaignHero";
import CampaignStatistic from "./CampaignStatistic";

import SEO from "../../SEO";
import CampaignExсuses from "./CampaignExсuses";
import CampaignPartners from "./CampaignPartners";
import CampaignAmbassadors from "./CampaignAmbassadors";
import CampaignJoin from "./CampaignJoin";
import CampaignAbcde from "./CampaignAbcde";
import CampaignSunSafe from "./CampaignSunSafe";
import CampaignInfo from "./CampaignInfo";
import CampaignFAQ from "./CampaignFAQ";

const CampaignMain = () => {
  return (
    <>
      <SEO
        title="Nie przekonuj się #nawłasnejskórze - Kampania edukacyjna o konieczności stosowania profilaktyki nowotworów skóry"
        description="W ciągu ostatnich 30 lat w Polsce zachorowalność na czerniaka złośliwego wzrosła aż o 300%. Chcemy pokazać, że wszyscy mniej lub bardziej, możemy być narażeni na zachorowanie na nowotwory skóry, bo czynników ryzyka jest bardzo dużo. "
        keywords="czerniak, badanie znamion, nawlasnejskorze, nawłasnejskórze, #nawłasnejskórze, na własnej skórze, twoje znamiona, twojeznamiona.pl, kampania społeczna, kampania edukacyjna"
      />
      <CampaignHero />
      <CampaignStatistic />
      <CampaignExсuses />
      <CampaignPartners />
      <CampaignAmbassadors />
      <CampaignJoin />
      <CampaignAbcde />
      <CampaignSunSafe />
      <CampaignInfo />
      <CampaignFAQ />
    </>
  );
};

export default CampaignMain;
