import React from "react";
import "./CampaignSunSafe.scss";

import Advice1Icon from "./advice1.png";
import Advice2Icon from "./advice2.png";
import Advice3Icon from "./advice3.png";
import Advice4Icon from "./advice4.png";
import Advice5Icon from "./advice5.png";
import Advice6Icon from "./advice6.png";
import Advice7Icon from "./advice7.png";
import Advice8Icon from "./advice8.png";
import CampaignSection from "../../components/CampaignSection";

const CARDS_CONTENT = [
  {
    icon: Advice1Icon,
    description: "Unikaj słońca",
  },
  {
    icon: Advice2Icon,
    description: "Nie korzystaj z solarium",
  },
  {
    icon: Advice3Icon,
    description: "Smaruj się kremem",
  },
  {
    icon: Advice4Icon,
    description: "Noś czapkę",
  },
  {
    icon: Advice5Icon,
    description: "Zakładaj okulary",
  },
  {
    icon: Advice6Icon,
    description: "Używaj odzieży anty UV",
  },
  {
    icon: Advice7Icon,
    description: "Obserwuj swoje znamiona",
  },
  {
    icon: Advice8Icon,
    description: "Badaj się regularnie",
  },
];

const CampaignSunSafe = () => {
  return (
    <CampaignSection
      id="join-us"
      className="campaign-sunsafe"
      subtitle="Chroń skórę"
      title="Jak chronić skórę przed promieniowaniem UV"
    >
      {CARDS_CONTENT.map(({ icon, description }, i) => {
        return (
          <div key={i} className="campaign-sunsafe__card">
            <div className="campaign-sunsafe__card-icon">
              <img src={icon} alt={description} />
            </div>
            <h6 className="campaign-sunsafe__card-description">
              {description}
            </h6>
          </div>
        );
      })}
    </CampaignSection>
  );
};

export default CampaignSunSafe;
