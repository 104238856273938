import React from "react";
import "./CampaignFAQ.scss";
import CampaignSection from "../../components/CampaignSection";

const CARDS_CONTENT = [
  {
    title: "Czym różni się pieprzyk od czerniaka?",
    description:
      "Na wczesnym etapie czerniak może wyglądać identycznie jak zwykłe znamię, a niektóre znamiona mogą naśladować czerniaka. Kluczowe jest więc dokładne obserwowanie znamion, najlepiej w powiększeniu. Należy zwrócić szczególną uwagę na znamiona, które nie są symetryczne, mają nieregularne, poszarpane krawędzie oraz mają nietypowy kolor. Powinny nas zaniepokoić znamiona przekraczające 6mm, które nagle urosły lub zmieniły swój kolor.",
  },
  {
    title: "Jak często należy kontrolować znamiona za pomocą dermatoskopu? ",
    description:
      "Wszystkie znamiona na naszym ciele powinny być badane dermatoskopem co najmniej raz w roku. Należy też samodzielnie monitorować zmiany na skórze w domu i sprawdzać, czy nie zmienia się ich rozmiar, kształt oraz kolor. Osoby, które mają większe ryzyko zachorowania na nowotwór skóry, powinny poddawać się badaniom dermatoskopowym częściej, zgodnie z rekomendacją lekarza - czasami nawet co kilka miesięcy.",
  },
  {
    title:
      "Jakie są najskuteczniejsze metody ochrony skóry przed szkodliwym działaniem promieniowania UV?",
    description:
      "Kluczowym elementem ochrony skóry są kremy z filtrem SPF. Zaleca się ich stosowanie przez cały rok, a idealnym wyborem są te z oznaczeniem SPF 30 lub wyższym. Dla dzieci zalecane są kremy z filtrem minimum SPF 50. Krem powinien być nałożony na skórę około 30 minut przed wyjściem na zewnątrz i należy go ponownie aplikować na skórę co 2-3 godziny. Aby zapewnić skuteczną ochronę, konieczne jest użycie co najmniej 2 mg kremu na cm² skóry, co jest równoznaczne z nałożeniem ilości odpowiadającej jednej łyżeczce do herbaty na skórę twarzy.",
  },
  {
    title:
      "Dlaczego badanie wideodermatoskopowe jest ważne w procesie wykrywania czerniaka?",
    description:
      "Wideodermatoskopia to kluczowe narzędzie w diagnostyce czerniaka, umożliwiające dokładne obserwowanie znamion w powiększeniu. Jest to metoda całkowicie nieinwazyjna, bez żadnych przeciwwskazań - dostępna dla kobiet w ciąży, osób z chorobami przewlekłymi, czy dzieci. Umożliwia wczesne wykrycie niepokojących zmian, co znacząco zwiększa szanse na skuteczne leczenie.",
  },
];

const CampaignFAQ = () => {
  return (
    <CampaignSection
      className="campaign-faq"
      subtitle="FAQ"
      title="Najczęstsze pytania pacjentów"
    >
      {CARDS_CONTENT.map(({ title, description }, i) => {
        return (
          <div key={i} className="campaign-faq__card">
            <h4 className="campaign-faq__card-title">{title}</h4>
            <p className="campaign-faq__card-description">{description}</p>
          </div>
        );
      })}
    </CampaignSection>
  );
};

export default CampaignFAQ;
