import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Button from "../../../Button";

import "./CampaignHero.scss";
import CtaJoinUs from "../../CtaJoinUs";
import { LOGO_LINK, navItems } from "../../CampaignHeader/CampaignHeader";
import HeroVideo from "./HeroVideo";
import CampaignLogoTitle from "../../CampaignLogoTitle";
import HeroPreview from "./hero-preview.png";
import SectionWrapper from "../../components/SectionWrapper";

const CampaignHero = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <SectionWrapper
      className={`campaign-hero ${
        isVideoPlaying ? "campaign-hero--video-playing" : ""
      }`}
    >
      <div id={LOGO_LINK} className="campaign-hero__section">
        <div className="campaign-hero__wrapper">
          <section className="campaign-hero__description">
            <h1 className="campaign-hero__title">
              Nie przekonuj się <CampaignLogoTitle />
            </h1>
            <p className="campaign-hero__paragraph">
              W ostatnich latach w Polsce i na całym świecie obserwujemy
              <b className="campaign-hero__description--bold">{" "}gwałtowny wzrost zapadalności na czerniaka.</b> W Polsce w ciągu
              ostatnich 30 lat zachorowalność na czerniaka wzrosła aż o 300%.{" "}
            </p>
            <div className="campaign-hero__actions  campaign-hero-actions">
              <Button>
                <CtaJoinUs />
              </Button>
              <Button
                type="secondary"
                size="L"
                className="campaign-hero-actions__learn-more"
              >
                <AnchorLink
                  href={`#campaign-statistic`}
                  className="campaign-hero-actions__learn-more-link"
                >
                  Dowiedz się więcej
                </AnchorLink>
              </Button>
            </div>
          </section>
          <HeroVideo
            url={"https://youtu.be/pNUCmeqpyM4"}
            heroPreview={HeroPreview}
            getIsPlaying={setIsVideoPlaying}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default CampaignHero;
