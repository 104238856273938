import { useEffect, useCallback, useState, useRef } from "react";

export const useScrollCarousel = (itemsAmount, scrollToIndexOnMount = true) => {
  const [index, setIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>();
  const [isScroll, setIsScroll] = useState(false);
  const [isRightBtnDisabled, setIsRightBtnDisabled] = useState(false);
  const [isLeftBtnDisabled, setIsLeftBtnDisabled] = useState(false);

  useEffect(() => {
    const checkIsScroll = () => {
      const element = sliderRef.current;

      if (element) {
        const hasScroll = element.scrollWidth > element.clientWidth;
        setIsScroll(hasScroll);
      }
    };

    setTimeout(() => {
      checkIsScroll();
    }, 0);

    window.addEventListener("resize", checkIsScroll);

    return () => window.removeEventListener("resize", checkIsScroll);
  }, [sliderRef]);

  useEffect(() => {
    const element = sliderRef.current;

    if (element) {
      const checkScrollPosition = () => {
        setIsLeftBtnDisabled(element.scrollLeft === 0);
        setIsRightBtnDisabled(
          element.scrollLeft === element.scrollWidth - element.clientWidth
        );
      };

      setTimeout(() => {
        checkScrollPosition();
      }, 0);

      element.addEventListener("scroll", checkScrollPosition);

      return () => element.removeEventListener("scroll", checkScrollPosition);
    }
  }, [sliderRef]);

  useEffect(() => {
    if (scrollToIndexOnMount) {
      setTimeout(() => {
        scrollToIndex(0);
      }, 500);
    }
  }, []);

  const scrollToIndex = useCallback((idx) => {
    const child = sliderRef.current?.children[idx] as HTMLDivElement;

    console.dir(child);

    if (child) {
      sliderRef.current.scrollTo({
        behavior: "smooth",
        left: child.offsetLeft,
      });
    }
  }, []);

  const handleScroll = useCallback(
    (ev: React.SyntheticEvent<HTMLDivElement>) => {
      const { scrollLeft, scrollWidth } = ev.currentTarget;

      const index = Math.round((scrollLeft / scrollWidth) * itemsAmount);
      // const child = ev.currentTarget.children[index] as HTMLDivElement;

      setIndex(index);
    },
    [itemsAmount]
  );

  const scrollBack = () => scrollToIndex(index - 1);
  const scrollNext = () => scrollToIndex(index + 1);

  return {
    wrapperProps: {
      ref: sliderRef,
      onScroll: handleScroll,
    },
    scrollBack,
    scrollNext,
    isScroll,
    isLeftBtnDisabled,
    isRightBtnDisabled,
  };
};
