import React, { useState } from "react";
import HeroVideo from "../CampaignHero/HeroVideo";
import HeroPreview from "../CampaignHero/hero-preview.png";
import HeroPreview1 from "../CampaignHero/bohater_1.png";
import HeroPreview2 from "../CampaignHero/bohater_2.png";
import HeroPreview3 from "../CampaignHero/bohater_3.png";
import HeroPreview4 from "../CampaignHero/bohater_4.png";
import HeroPreview5 from "../CampaignHero/bohater_5.png";
import HeroPreview6 from "../CampaignHero/bohater_6.png";
import HeroPreview7 from "../CampaignHero/bohater_7.png";
import CampaignSection from "../../components/CampaignSection";
import CampaignCarousel from "../../components/CampaignCarousel";
import "./CampaignExсuses.scss";

const CARDS_CONTENT = [
  {
    videoUrl: "https://youtu.be/SImjgC6hIPk",
    heroPreview: HeroPreview4,
    heroInfo: "Marta, 32 lata",
  },
  {
    videoUrl: "https://youtu.be/pyx1LJ9Ixxg",
    heroPreview: HeroPreview2,
    heroInfo: "Zbyszek, 39 lat",
  },
  {
    videoUrl: "https://youtu.be/tlo7YYVgalw",
    heroPreview: HeroPreview3,
    heroInfo: "Anna, 24 lata",
  },
  {
    videoUrl: "https://youtu.be/zbNgs0RsVVM",
    heroPreview: HeroPreview1,
    heroInfo: "Bartek, 22 lata",
  },
  {
    videoUrl: "https://youtu.be/opTVr6uez3k",
    heroPreview: HeroPreview5,
    heroInfo: "Adrian, 28 lat",
  },
  {
    videoUrl: "https://youtu.be/FLCiSxoyzLE",
    heroPreview: HeroPreview7,
    heroInfo: "Stanisława, 68 lat",
  },
  {
    videoUrl: "https://youtu.be/VP09txKsuc0",
    heroPreview: HeroPreview6,
    heroInfo: "Anna, 38 lat",
  },
];

const CampaignExсuses = () => {
  return (
    <CampaignSection
      className="campaign-excuses"
      title={
        <>
          A Ty? Dlaczego{" "}
          <span className="campaign-excuses__title--blue">nie obserwujesz</span>{" "}
          <span className="campaign-excuses__title--yellow">znamion</span>?
          <br />
          Jaki jest powód, że nie używasz filtrów UV?
        </>
      }
      paragraph={
        <>
          Poznaj naszych bohaterów, którzy bagatelizują problem, nie badają
          znamion i nie stosują ochrony skóry przed promieniowaniem UV.
          Posłuchaj jakie wymówki i mity obecne są w naszym
          społeczeństwie.
          <br />
          Czy gdzieś już to słyszałaś/eś?
        </>
      }
    >
      <CampaignCarousel>
        {CARDS_CONTENT.map(({ heroPreview, videoUrl, heroInfo }, i) => {
          return (
            <div key={i} className="campaign-excuses__card">
              <div className="campaign-excuses__video-wrapper">
                <HeroVideo heroPreview={heroPreview} url={videoUrl} isModal />
              </div>
              <h4 className="campaign-excuses__hero-name">{heroInfo}</h4>
            </div>
          );
        })}
      </CampaignCarousel>
    </CampaignSection>
  );
};

export default CampaignExсuses;
