import React from "react";
import Abcde from "../../../landingPage/Abcde";
import "./CampaignAbcde.scss";
import { navItems } from "../../CampaignHeader/CampaignHeader";
import SectionWrapper from "../../components/SectionWrapper";

const DESCRIPTION =
  "Większość czerniaków w Polsce rozpoznawanych jest zbyt późno, co znacznie utrudnia późniejsze leczenie tego niebezpiecznego nowotworu.";

const TABS = {
  A: {
    label: "Zasada A",
    h2: "Niesymetryczne znamiona",
    sub:
      "Objawy czerniaka mogą być jednak zupełnie nieuchwytne gołym okiem, dlatego tak ważne jest oglądanie znamion w powiększeniu dermatoskopem.",
    txt:
      "Klasyczny dermatoskop, który stosują często dermatolodzy wygląda jak lupka- szkło powiększające z wbudowanym źródłem światła. Takie dermatoskopy powiększają obraz zwykle ok. 10-20 krotnie oraz umożliwiają profilaktyczne badanie niepokojących znamion.",
  },
  B: {
    label: "B - Border",
    h2: "Nierówne brzegi znamienia",
    sub:
      "Nierówne, poszarpane brzegi, rozmyta lub zatarta granica pomiędzy znamieniem a zdrową skórą oraz  'rozlewania się' barwnika na otaczającą skórę powinno budzić niepokój.",
    txt:
      "Typowe znamiona są zwykle ostro odgraniczone od podłoża, mają równe brzegi i przyjmują zazwyczaj owalny lub okrągły kształt. Każde znamię, które ma nierówne, postrzępione granice powinno zostać zweryfikowane w badaniu dermatoskopowym.",
  },
  C: {
    label: "C - Color",
    h2: "Niejednolity kolor znamienia",
    sub:
      "Występowanie w znamieniu więcej niż jednego koloru, nierówne rozłożenie barwnika, występowanie skupisk ciemniejszego koloru oraz obszary odbarwienia mogą być sygnałami alarmowymi.",
    txt:
      "Typowe znamiona charakteryzują się jednolitością barwy, zwykle są jednokolorowe, typowo w kolorze brązowym. Każde wielobarwne znamię, o nieregularnych  granicach przejścia jednego koloru w drugi, powinno zostać zbadane dermatoskopowo.",
  },
  D: {
    label: "D - Diameter",
    h2: "Duża średnica znamienia",
    sub:
      "Każde znamię o średnicy powyżej 6 mm powinno być pod stałą obserwacją. Bardzo ważnym elementem badania jest ocena dynamiki wzrostu znamion.",
    txt:
      "Jeśli znamię wzrasta w szybkim tempie lub ma powyżej 6 mm i dodatkowo niepokojące cechy powinno zostać skonsultowane z lekarzem. Osoby z zespołem znamion dysplastycznych charakteryzującym się obecnością wielu często dużych, atypowych znamion znajdują się zwykle pod stałą kontrolą dermatoskopową.",
  },
  E: {
    label: "E - Evolving",
    h2: "Nowa lub zmieniająca się zmiana",
    sub:
      "Czerniaki w większości pojawiają się “de novo” - jako nowe znamiona, zwykle odmienne od pozostałych, często charakteryzujące  się szybszym wzrostem. Na uwagę zasługują  także zmiany skórne, które zmieniają się w czasie.",
    txt:
      "Podstawą obserwacji znamion jest  analiza porównawcza zmian w nich zachodzących a najlepszą metodą, która umożliwia ocenę struktur znamion na przestrzeni czasu jest wideodermatoskopia. Jeśli Twoje znamię jest niesymetryczne, ma poszarpane, nierówne brzegi, niejednolity kolor, jest większe niż 6 mm lub dynamicznie zmienia się w czasie należy jak najszybciej umówić się na badanie dermatoskopowe.",
  },
};

const CampaignAbcde = () => {
  return (
    <div id={navItems.worthToKnow.link}>
      <SectionWrapper className="campaign-abcde">
        <Abcde
          className="campaign-abcde__abcde-wrapper"
          description={DESCRIPTION}
          tabsContent={TABS}
        />
      </SectionWrapper>
    </div>
  );
};

export default CampaignAbcde;
