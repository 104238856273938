import React from "react";
import "./CampaignAmbassadors.scss";

import HeroPreviewGalus from "./ambasador_gallus2.png";
import HeroPreviewCzyzewska from "./ambasador_czyzewska.png";
import HeroPreviewRaczkiewicz from "./ambasador_raczkiewicz.png";
import HeroPreviewHoma from "./ambasador_homa.png";
import HeroPreviewBaranska from "./ambasador_baranska.png";
import HeroPreviewGulko from "./ambasador_ania_gulko.png";
import HeroPreviewPulawska from "./ambasador_katarzyna_pulawska.png";
import { navItems } from "../../CampaignHeader/CampaignHeader";
import CampaignSection from "../../components/CampaignSection";
import CampaignCarousel from "../../components/CampaignCarousel/CampaignCarousel";
import { Link } from "gatsby";
import Button from "../../../Button";

const CARDS_CONTENT = [
  {
    logo: HeroPreviewCzyzewska,
    name: "Agnieszka Czyżewska",
    description:
      "Specjalistka w dziedzinie onkologii klinicznej. Współzałożycielka Ogólnopolskiego Centrum Badania Znamion - Twoje Znamiona. Doświadczenie zawodowe zdobywała m.in w Wielkopolskim Centrum Onkologii. Propagatorka regularnych badań i edukacji społeczeństwa na temat wczesnych objawów nowotworów skóry oraz czynników ryzyka z nim związanymi.",
    link: "/nie-przekonuj-sie-na-wlasnej-skorze/o-kampanii#czyzewska",
    label: "Posłuchaj",
  },
  {
    logo: HeroPreviewGalus,
    name: "dr n. med. Łukasz Galus",
    description:
      "Specjalista onkologii klinicznej. Bierze udział w wielu badaniach klinicznych oceniających nowe leki stosowane u chorych na czerniaka. Bada nowe strategie leczenia w tym immunoterapię nowotworów i możliwości jej wykorzystania. Pracuje w Szpitalu Klinicznym im. H. Święcickiego UM w Poznaniu na Oddziale Onkologii Klinicznej i Doświadczalnej oraz w Wielkopolskim Centrum Onkologii.",
    link: "/nie-przekonuj-sie-na-wlasnej-skorze/o-kampanii#galus",
    label: "Posłuchaj",
  },
  {
    logo: HeroPreviewRaczkiewicz,
    name: "Dorota Raczkiewicz",
    description:
      "Politolog, dziennikarka, prezeska Fundacji „Dar Szpiku”, szefowa Drużyny Szpiku. Siedmiokrotnie wraz z Drużyną Szpiku otrzymała tytuł Poznański Wolontariusz Roku, a w 2012 roku wyróżnienie w Konkursie Wielkopolski Człowiek Roku oraz Srebrny Krzyż Zasługi przyznany przez Prezydenta Bronisława Komorowskiego.",
  },
  {
    logo: HeroPreviewBaranska,
    name: "Aleksandra Wiederek-Barańska",
    description:
      "Influencerka, pacjentka onkologiczna. Na co dzień obala mity związane z chorobami nowotworowymi i motywuje innych do tego, aby czerpać z życia pełnymi garściami. Autorka petycji do Ministra Zdrowia w sprawie refundacji leku wykorzystywanego w terapii raka piersi, którą podpisało prawie 19 tys. osób.",
  },
  {
    logo: HeroPreviewHoma,
    name: "dr n. med. Marta Homa",
    description:
      "Wykładowczyni na UAM, Uniwersytecie Medycznym, Uniwersytecie SWPS. Aktywnie uczestniczy w licznych konferencjach i szkoleniach psychologicznych z zakresu psychologii klinicznej, neuropsychologii, psychoonkologii i interwencji kryzysowej. Członkini Polskiego Towarzystwa Psychoonkologicznego, Polskiego Towarzystwa Psychologicznego oraz Wielkopolskiego Towarzystwa Terapii Systemowej.",
  },
  {
    logo: HeroPreviewGulko,
    name: "Ania Gulko",
    description:
      "Założycielka i prezeska Fundacji No Shame - Bez Wstydu, z wykształcenia psycholog. Specjalizuje się w edukacji społecznej dotyczącej pielęgnacji skóry, z uwzględnieniem aspektów fizycznych i psychicznych. Aktywnie propaguje profilaktykę ochrony skóry i regularne badania dermatologiczne. Jej działania dowodzą, że niewielkie inicjatywy mogą przynieść znaczące efekty w społeczeństwie.",
  },
  {
    logo: HeroPreviewPulawska,
    name: "Katarzyna Puławska",
    description:
      "Propagatorka zdrowego stylu życia, miłośniczka zdrowia w każdym jego aspekcie. Influencerka motywująca do zmiany nawyków żywieniowych, pokazuje jak żyć zdrowo na co dzień bez cukru i przetworzonej żywności. Znana z prowadzenia kulinarnego profilu onaciaglegotuje ze zdrowymi przepisami. Autorka książki “Bez cukru da się żyć“.",
  },
];

const CampaignAmbassadors = () => {
  return (
    <CampaignSection
      id={navItems.ambassadors.link}
      className="campaign-ambassadors"
      subtitle="Ty także możesz dołączyć do naszej akcji!"
      title="Poznaj naszych ambasadorów"
    >
      <CampaignCarousel theme="dark">
        {CARDS_CONTENT.map(({ logo, name, description, link, label }, i) => {
          return (
            <div key={i} className="campaign-ambassadors__card">
              <div className="campaign-ambassadors__card-logo-container">
                <img
                  src={logo}
                  className="campaign-ambassadors__card-logo"
                  alt={name}
                />
              </div>

              <h4 className="campaign-ambassadors__card-name">{name}</h4>
              <p className="campaign-ambassadors__card-description">
                {description}
              </p>
              {link && label && (
                <Button type="secondary" size="M" className="campaign-ambassadors__card-link">
                  <Link to={link}>{label}</Link>
                </Button>
              )}
            </div>
          );
        })}
      </CampaignCarousel>
    </CampaignSection>
  );
};

export default CampaignAmbassadors;
