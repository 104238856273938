import React from "react";
import "./CampaignPartners.scss";
import CampaignLogoTitle from "../../CampaignLogoTitle";
import PartnerLogo1 from "./partner1.png";
import PartnerLogo2 from "./partner2.png";
import PartnerLogo4 from "./partner4.png";
import PartnerLogo5 from "./partner5.png";
import PartnerLogo6 from "./partner6.png";
import PartnerLogo7 from "./partner7.png";
import PartnerLogo8 from "./partner8.png";
import PartnerLogo9 from "./partner9.png";

import { navItems } from "../../CampaignHeader/CampaignHeader";
import CampaignSection from "../../components/CampaignSection";

const CARDS_CONTENT = [
  {
    logo: PartnerLogo1,
    link: "https://www.pkpo.pl/",
    name: "Polska Koalicja Pacjentów Onkologicznych",
    description:
      "Polska Koalicja Pacjentów Onkologicznych zrzesza 47 organizacji z całego kraju. Od 13 lat pomaga chorym na nowotwory w Polsce, niosąc wiedzę, nadzieję i wsparcie. PKPO jako organizacja parasolowa, koncentruje się na poprawie sytuacji wszystkich pacjentów onkologicznych i hematoonkologicznych.",
  },
  {
    logo: PartnerLogo2,
    link: "https://fundacjarakiety.pl/",
    name: "Fundacja Rakiety",
    description:
      "Fundacja Rakiety wspiera osoby z chorobami onkologicznymi oraz ich najbliższych. Zapewnia specjalistyczną pomoc, tj. wsparcie psychologiczne, psychoonkologiczne, psychiatryczne oraz onkodietetyczne, pomaga także w organizacji leczenia i organizuje wsparcie finansowe.",
  },
  {
    logo: PartnerLogo4,
    link: "https://druzynaszpiku.pl/",
    name: "Drużyna Szpiku",
    description:
      "Fundacja  działa na rzecz profilaktyki chorób nowotworowych krwi oraz propaguje ideę pozyskiwania dawców szpiku. Dodatkowo wspiera osoby chore i ich rodziny, pomagając w przeciwdziałaniu wykluczeniu społecznemu. Fundacja udziela również pomocy materialnej.",
  },
  {
    logo: PartnerLogo5,
    link: "https://dzieciakichojraki.pl/",
    name: "Dzieciaki Chojraki",
    description:
      "Dzieciaki Chojraki to stowarzyszenie Wspierania Transplantacji Szpiku i Onkologii Dziecięcej. Oferują wsparcie materialne i edukacyjne dla dzieci w trakcie procedury medycznej i ich rodzin. Organizują szkolenia i tworzą materiały edukacyjne, aby promować w społeczeństwie wiedzą o transplantacji szpiku.",
  },
  {
    logo: PartnerLogo6,
    link: "https://www.wcp.org.pl/",
    name: "Warszawskie Centrum Psychoonkologii",
    description:
      "Warszawskie Centrum Psychoonkologii zapewnia wsparcie psychologiczne dla osób chorych onkologicznie i ich bliskich na każdym etapie choroby i zdrowienia. WCP działa na podstawie najnowszej wiedzy naukowej i klinicznej z zakresu psychoonkologii i psychoterapii.",
  },
  {
    logo: PartnerLogo7,
    link: "https://zyciezrakiem.org/",
    name: "Fundacja Życie z Rakiem",
    description:
      "Fundacja Życie z Rakiem organizuje warsztaty, które pomagają pacjentom onkologicznym nauczyć się funkcjonować i żyć ze swoją diagnozą. Z ich pomocą pacjent uczy się, jak zorganizować swoje życie na nowo i wspomóc proces leczenia. Poprawiają rzeczywistość pacjentów onkologicznych.",
  },
  {
    logo: PartnerLogo8,
    link: "https://fundacjakochamzycie.org/",
    name: "Fundacja Kocham Życie",
    description:
      "Fundacja Kocham Życie podnosi świadomość społeczeństwa na temat chorób nowotworowych. Organizuje kursy i seminaria edukujące ludzi w tematach związanych z profilaktyką przed rakiem oraz przebiegiem choroby. Dodatkowo zapewnia wsparcie psychiczne osób chorych na nowotwory i ich najbliższych.",
  },
  {
    logo: PartnerLogo9,
    link: "https://www.noshamefoundation.com/",
    name: "Fundacja No Shame",
    description:
      "Fundacja No Shame - Bez Wstydu powstała z pierwotnym celem pomocy osobom chorym na łuszczycę. Obecnie nasze działania obejmują również inne choroby skóry. Naszą misją jest walka z dyskryminacją ze względu na wygląd oraz edukacja psychologiczna. Dążymy do holistycznego podejścia do leczenia i pielęgnacji skóry. Wierzymy, że każda osoba z chorobą skóry zasługuje na wsparcie nie tylko w aspekcie medycznym, ale również emocjonalnym i społecznym.",
  },
];

const CampaignPartners = () => {
  return (
    <CampaignSection
      id={navItems.partners.link}
      className="campaign-partners"
      title={
        <>
          Poznaj naszych Partnerów
          <br />
          i dołącz do akcji <CampaignLogoTitle />
        </>
      }
      subtitle={<>Zobacz kto nas wspiera</>}
      paragraph="Poznaj naszych wyjątkowych Partnerów i ich inspirującą pracę. Dzięki
      ich oddaniu i zaangażowaniu pomagają nie tylko osobom chorym, ale
      także budują świadomość na temat chorób nowotworowych. Dowiedz się
      więcej o naszych partnerach i odkryj, jak razem tworzymy pozytywne
      zmiany oraz promujemy wiedzę na temat profilaktyki nowotworów skóry."
    >
      {CARDS_CONTENT.map(({ logo, link, name, description }, i) => {
        return (
          <div key={i} className="campaign-partners__card">
            <div className="campaign-partners__card-logo">
              <a
                className="campaign-partners__link"
                href={link}
                target="_blank"
              >
                <img
                  width="100%"
                  src={logo}
                  className="campaign-partners__card-icon"
                  alt={name}
                />
              </a>
            </div>

            <h4 className="campaign-partners__card-name">{name}</h4>
            <p className="campaign-partners__card-description">{description}</p>
          </div>
        );
      })}
    </CampaignSection>
  );
};

export default CampaignPartners;
