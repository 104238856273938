import React from "react";
import "./CampaignInfo.scss";

import Image1 from "./image1.png";
import Image4 from "../../../../img/home_wideodermatoskopia.png";
import SectionDescription from "../../components/SectionDescription";
import SectionWrapper from "../../components/SectionWrapper";

const CampaignInfo = () => {
  return (
    <SectionWrapper className="campaign-info">
      <div className="campaign-info__section">
        <div className="campaign-info__wrapper">
          <div className="campaign-info__info-container campaign-info__info-container--first-row">
            <div className="campaign-info__description-container">
              <SectionDescription
                className="campaign-info"
                subtitle="Jak rozpoznać czerniaka?"
                title="Czerniak nie zawsze jest czarny"
                paragraph="Pamiętaj czerniak nie zawsze jest czarny, nie zawsze szybko
              rośnie, a zmian możesz nie zauważyć gołym okiem."
              >
                <p className="campaign-info__sub-paragraph">
                  Klasyczny dermatoskop, który stosują często dermatolodzy
                  wygląda jak lupka- szkło powiększające z wbudowanym źródłem
                  światła. Takie dermatoskopy powiększają obraz zwykle ok. 10-20
                  krotnie oraz umożliwiają profilaktyczne badanie niepokojących
                  znamion.
                </p>
              </SectionDescription>
            </div>
            <div className="campaign-info__image-container">
              <img width="100%" src={Image1} alt="Badanie dermatoskopem ręcznym" />
            </div>
          </div>

          <div className="campaign-info__info-container campaign-info__info-container--second-row">
            <div className="campaign-info__image-container">
              <img width="100%" src={Image4} alt="Badanie wideodermatoskopowe pleców" />
            </div>

            <div className="campaign-info__description-container">
              <SectionDescription
                className="campaign-info"
                subtitle="Na czym to polega?"
                title="Wideodermatoskopia i dermatoskopia"
                paragraph="Wideodermatoskopia to najdokładniejsza i preferowana obecnie
                metoda badania znamion."
              >
                <p className="campaign-info__sub-paragraph">
                  Dzięki wideodermatoskopii lekarze mają możliwość dokładnego
                  badania podejrzanych znamion i zmian skórnych w dużym
                  powiększeniu na monitorze. Ponadto system archiwizacji danych
                  pozwala zapisywać obraz znamion, które wymagają regularnej
                  kontroli i porównywania ich obrazów z biegiem czasu.
                </p>
                <p className="campaign-info__sub-paragraph">
                  Dermatoskopia to badanie, które również pozwala na ocenę
                  architektury zmian skórnych i analizę różnych pigmentacji, co
                  znacznie zwiększa szanse na wychwycenie potencjalnie
                  niebezpiecznych zmian w bardzo wczesnym stadium.
                </p>
              </SectionDescription>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default CampaignInfo;
