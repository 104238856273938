import React from "react";

import "./CampaignPage.scss";

import CampaignLayout from "../../components/campaign/CampaignLayout/CampaignLayout";
import CampaignMain from "../../components/campaign/CampaignMain";

const CampaignPage = ({ location }) => {
  return (
    <CampaignLayout location={location}>
      <CampaignMain />
    </CampaignLayout>
  );
};

export default CampaignPage;
